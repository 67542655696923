import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { Instagram } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import DisconnectModal from "./DisconnectModal";
import AuthActions from "../../../../redux/auth/authActions";

let instagramAuthUrl = process.env.REACT_APP_INSTAGRAM_LOGIN_URL;
const ROOT_URL = process.env.REACT_APP_API_URL;

export const InstagramButton = ({ pcInfo }) => {
  console.log(pcInfo);
  const dispatch = useDispatch();
  const isInstagramAuthenticated = useSelector((state) => state.auth.InstagramAuthenticated);
  const [isAssistantEnabled, setIsAssistantEnabled] = useState(pcInfo.PCInformation.instagram_assistant);
  const [showModal, setShowModal] = useState(false);
  const [instagramData, setInstagramData] = useState({ username: '', profile_picture_url: '' });
  const [t] = useTranslation("global");

  useEffect(() => {
    // Escuchar los mensajes de la ventana emergente
    const fetchInstagramData = async () => {
      try {
        /*await axios.put(`${ROOT_URL}/api/instagram/assistant/changeStatus`, {
          id: pcInfo.PCInformation.id_user,
          status: 1
        });*/
        const response = await axios.get(`${ROOT_URL}/api/instagram/profile/${pcInfo.PCInformation.id_user}`, {
          headers: {
            'sec-ch-ua-platform': 'macOS',
            'Accept': 'application/json, text/plain, */*',
            'sec-ch-ua': '"Google Chrome";v="131", "Chromium";v="131", "Not_A Brand";v="24"',
            'Content-Type': 'application/json',
            'sec-ch-ua-mobile': '?0',
            'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36',
            'Sec-Fetch-Site': 'cross-site',
            'Sec-Fetch-Mode': 'cors',
            'Sec-Fetch-Dest': 'empty',
            'host': 'inkup-back.com'
          }
        });
        const data = response.data.result[0];
        setInstagramData(data);
      } catch (error) {
        console.error('Error fetching Instagram data:', error);
      }
    };

    if (isInstagramAuthenticated) {
      fetchInstagramData();
    }

    const handleAuthMessage = (event) => {
      if (event.data.type === "INSTAGRAM_AUTH_SUCCESS") {
        dispatch(AuthActions.updateInstagramAuth(true)); // Actualiza el estado de autenticación en Redux
      }
    };

    window.addEventListener("message", handleAuthMessage);

    return () => {
      window.removeEventListener("message", handleAuthMessage);
    };
  }, [dispatch]);

  const handleAuthenticate = () => {
    const width = 500;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
      instagramAuthUrl = "https://www.instagram.com/accounts/login/?force_authentication=1&next=%2Foauth%2Fauthorize%3Fenable_fb_login%3D0%26client_id%3D1056355562803459%26redirect_uri%3Dhttps%3A%2F%2Fapp.inkup.io%2FinstagramAuthentication%26response_type%3Dcode%26scope%3Dinstagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish";
    }

    window.open(
      instagramAuthUrl,
      "Instagram Authentication",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  const handleDisconnect = () => {
    dispatch(AuthActions.updateInstagramAuth(false));
  };


  const toggleAssistant = async () => {
    let newValue = isAssistantEnabled ? 0 : 1;
    setIsAssistantEnabled(newValue);

    try {
      await axios.put(`${ROOT_URL}/api/instagram/assistant/changeStatus`, {
        id: pcInfo.PCInformation.id_user,
        status: newValue
      });
      console.log('Estado actualizado en la base de datos.');
    } catch (error) {
      console.error('Error al actualizar en la base de datos:', error);
    }
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      padding: '24px',
      backgroundColor: '#fff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      width: '100%',
      maxWidth: '500px',
      marginBottom: '10px'
    }}>
      {isInstagramAuthenticated ? (
        <>
        <h4 style={{ fontSize: '1.25rem', fontWeight: '600', margin: '0' }}>{t("instagram.header")}</h4>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '8px',
          marginTop: '16px',
        }}>
          {/* Imagen y Username */}
          <img
            src={instagramData.profile_picture_url}
            alt="Instagram profile"
            style={{
              width: '80px',
              height: '80px',
              borderRadius: '50%',
              objectFit: 'cover',
              border: '2px solid #ddd'
            }}
          />
          <p style={{
            fontSize: '1rem',
            fontWeight: '500',
            color: '#333',
            margin: '8px 0 0'
          }}>
            {instagramData.username}
          </p>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '16px',
          marginTop: '16px',
          width: '100%'
        }}>
          {/* Mensaje y Botón en la misma línea */}
          <p style={{ color: 'green', fontWeight: '500', margin: '0' }}>{t("instagram.connectedMessage")}</p>
          <Button
            variant="primary"
            onClick={() => setShowModal(true)} // Muestra el modal de confirmación
            style={{
              backgroundColor: '#3E3E42',
              borderColor: '#3E3E42',
              padding: '8px 16px',
              flexShrink: 0
            }}
          >
            {t("instagram.textButtonDisconnect")}
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginTop: '16px' }}>
          <label className="switch">
            <input type="checkbox" checked={isAssistantEnabled} onChange={toggleAssistant} />
            <span className="slider round"></span>
          </label>
          <span>{t("instagram.textEnable")}</span>
        </div>
      </>
      ) : (
        <>
          <h4 style={{ fontSize: '1.25rem', fontWeight: '600', margin: '0' }}>{t("instagram.header")}</h4>
          <p style={{ color: '#6c757d', margin: '0' }}>{t("instagram.title")}</p>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              variant="primary"
              onClick={handleAuthenticate}
              style={{
                backgroundColor: '#3E3E42',
                borderColor: '#3E3E42',
                padding: '8px 16px'
              }}
            >
              <Instagram className="me-2" />
              {t("instagram.textButton")}
            </Button>
          </div>
        </>
      )}
      <DisconnectModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={handleDisconnect} // Actualiza el estado de autenticación en caso de éxito
        title={t("instagram.modalMessage")}
        message={t("instagram.modalMessage")}
        userId={pcInfo.PCInformation.id_user} // Pasar el ID del usuario al modal
        isAssistantEnabled={isAssistantEnabled}
      />
    </div>
  );
};