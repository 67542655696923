import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Alert, Spinner } from "react-bootstrap";

const ROOT_URL = process.env.REACT_APP_INSTAGRAM_SERVICE_URL;

export const InstagramAuthentication = ({ user }) => {
  const location = useLocation();
  const [status, setStatus] = useState("loading"); // loading, success, error

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      axios
        .post(`${ROOT_URL}/api/auth/access_token`, { code, userId: user.profile.id })
        .then(() => {
          setStatus("success");

          // Enviar mensaje a la ventana principal indicando que la autenticación fue exitosa
          window.opener.postMessage({ type: "INSTAGRAM_AUTH_SUCCESS" }, "*");

          // Cerrar la ventana después de unos segundos
          setTimeout(() => {
            window.close();
          }, 2000);
        })
        .catch(() => {
          setStatus("error");
        });
    } else {
      setStatus("error");
    }
  }, [location, user]);


  const [t] = useTranslation("global");

  return (
    <div
        className="instagram-auth-container"
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        {status === "loading" && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {status === "success" && (
          <Alert variant="success">
            {t("instagram.authenticationSuccess")}
          </Alert>
        )}
        {status === "error" && (
          <Alert variant="danger">
            {t("instagram.authenticationError")}
          </Alert>
        )}
      </div>
  );
};